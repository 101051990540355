define('ember-leaflet/helpers/icon', ['exports', 'ember'], function (exports, _ember) {
  /* global L */

  var helper = _ember['default'].Helper.helper;

  var isFastBoot = typeof FastBoot !== 'undefined';

  var icon = isFastBoot ? function () {} : function icon(_, hash) {
    return L.icon(hash);
  };

  exports.icon = icon;
  exports['default'] = helper(icon);
});