define('ember-leaflet/helpers/lat-lng-bounds', ['exports', 'ember'], function (exports, _ember) {
  /* global L */

  var helper = _ember['default'].Helper.helper;

  var isFastBoot = typeof FastBoot !== 'undefined';

  var latLngBounds = isFastBoot ? function () {} : function (latLngs) {
    return L.latLngBounds(latLngs);
  };

  exports.latLngBounds = latLngBounds;
  exports['default'] = helper(latLngBounds);
});