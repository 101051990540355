define('ember-leaflet/helpers/div-icon', ['exports', 'ember'], function (exports, _ember) {
  /* global L */

  var helper = _ember['default'].Helper.helper;

  var isFastBoot = typeof FastBoot !== 'undefined';

  var divIcon = isFastBoot ? function () {} : function divIcon(_, hash) {
    return L.divIcon(hash);
  };

  exports.divIcon = divIcon;
  exports['default'] = helper(divIcon);
});