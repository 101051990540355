define('ember-leaflet/helpers/point', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  /* global L */

  var helper = _ember['default'].Helper.helper;

  var isFastBoot = typeof FastBoot !== 'undefined';

  var point = isFastBoot ? function () {} : function point(params) {
    var _L;

    return (_L = L).point.apply(_L, _toConsumableArray(params));
  };

  exports.point = point;
  exports['default'] = helper(point);
});